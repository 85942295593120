<template>
  <main>
    <div class="container">
      <!-- Header -->
      <header class="user__header">
        <navigations-breadcrumb :children="breadcrumbs" />

        <div class="row">
          <h1 class="user__title">{{ $t('user-portal.user_vouchers_title') }}</h1>
        </div>
      </header>
      
      <!-- Loading -->
      <div
        v-if="loading"
        class="user__loader">
        <ui-loader />
      </div>

      <div
        v-if="!loading"
        class="user__body flow">
        <!-- Empty state -->
        <data-empty
          :description="$t('user-portal.user_no_vouchers_description')"
          :title="$t('user-portal.user_no_vouchers')"
          :primary-action="emptyAction ? emptyAction.primary : null"
          @click="emptyAction ? emptyAction.action() : () => {}"
          v-if="isEmpty"
        />

        <div 
          class="-grid" 
          v-if="!isEmpty">
          <ui-card-voucher 
            :key="voucher.id"
            :voucher="voucher"
            @qr="onQr"
            v-for="voucher in vouchers"
          />
        </div>
      </div>
    </div>

    <modal-voucher
      :visible="showVoucher"
      :voucher="selected"
      @close="onQrClose"
      v-if="showVoucher"
    />
  </main>
</template>

<script>
import ModalVoucher from '@/modules/user/components/modals/voucher.vue'
import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'
import UiCardVoucher from '@/modules/user/components/ui/card-voucher.vue'

export default {
  name: 'UserVouchers',

  components: {
    ModalVoucher,
    NavigationsBreadcrumb,
    UiCardVoucher
  },

  inject: [
    '$loyalty',
    '$nft',
    '$user',
  ],

  data() {
    return {
      breadcrumbs: [
        { label: this.$t('user-portal.user_account_title'), href: { name: 'sayl-user-portal.home' } },
        { label:  this.$t('user-portal.user_vouchers_title'), href: '#' }
      ],
      loading: true,

      showVoucher: false,
      selected: null
    }
  },

  computed: {
    emptyAction() {
      let ret = null

      if(this.$basil.get(this.$loyalty, 'program.allowVouchersRedemption', false)) {
        ret = {
          action: () => this.$router.push({ name: 'sayl-user-portal.loyalty.vouchers' }).catch(() => {}),
          primary: this.$t('user-portal.go_to_loyalty_vouchers')
        }
      }

      return ret
    },

    isEmpty() {
      return this.vouchers.length === 0
    },

    nftVouchers() {
      let ret = this.$nft.vouchers || []
      return ret
    },    

    vouchers() {
      let ret = this.$user.vouchers || []
      return ret.concat(this.nftVouchers)
    },
  },

  methods: {
    onQr(voucher) {
      this.selected = voucher
      this.showVoucher = true
    },

    onQrClose() {
      this.showVoucher = false
      this.selected = null
    },

    async reset() {
      try {
        this.loading = true
        await this.$user.getVouchers()
        
        if(!this.$basil.isNil(this.$basil.get(this.$user, 'user.walletAddress'))) {
          await this.$nft.getNftVouchers()
        }
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    }
  },

  mounted() {
    this.reset()
  }
}
</script>