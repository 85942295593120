<template>
  <component
    :class="classes"
    :is="hasChildren ? 'details' : 'button'"
    :open="hasChildren ? true : false"
    @click="onQr">
    <component 
      :is="hasChildren ? 'summary' : 'div'"
      class="user-voucher-card__body">
      <div class="user-voucher-card__content">
        <p class="user-voucher-card__name">{{ name }}</p>

        <p 
          class="user-voucher-card__type" 
          v-if="validityPeriodDate && !isTemplate"
        >{{ $t('user-portal.voucher_to_date', { date: validityPeriodDate }) }}</p>

        <div 
          class="user-voucher-card__perks" 
          v-if="voucher.type !== 'perks' || (voucher.perks && voucher.perks.length > 0)">
          <div class="user-voucher-card__perk">
            <span
              class="user-voucher-card__perk-title" 
              v-if="voucher.type !== 'perks'"
            >{{ discount }}</span>

            <template v-else>
              <div
                class="user-voucher-card__perk-wrapper"
                :key="perk.id"
                v-for="perk in voucher.perks">
                <div class="user-voucher-card__perk-title">
                  <ui-icon glyph="check"/>

                  <span>{{ perk.name }}</span>
                </div>

                <p class="user-voucher-card__perk-description">{{ perk.clientMessage }}</p>
              </div>
            </template>
          </div>
        </div>

        <div 
          class="user-voucher-card__generation" 
          v-if="isTemplate && generation != null">
          <p 
            class="user-voucher-card__type" 
            v-if="lastGeneration"
          >{{ $t('user-portal.voucher_last_generation', { value: lastGeneration }) }}</p>

          <p
            class="user-voucher-card__type" 
            v-if="nextGeneration"
          >{{ $t('user-portal.voucher_next_generation', { value: nextGeneration }) }}</p>
        </div>
      </div>

      <div class="user-voucher-card__column">
        <data-tag 
          class="user-voucher-card__status"
          :size="$pepper.Size.S"
          v-if="voucher.web3 === true && !isChild && !isTemplate"
        >{{ $t('user-portal.voucher_is_web3') }}</data-tag>

        <data-tag 
          :appearance="$pepper.Appearance.PRIMARY"
          class="user-voucher-card__status"
          :size="$pepper.Size.S"
          v-if="!isChild"
        >{{ $t(`user-portal.user_voucher_type_${ voucher.type }`) }}</data-tag>

        <data-tag 
          v-bind="statusAttrs"
          v-if="statusLabel"
        >{{ statusLabel }}</data-tag>
      </div>
    </component>

    <div 
      class="user-voucher-card__children" 
      v-if="isTemplate && hasChildren">
      <div class="user-voucher-card__children-list">
        <user-voucher-card
          class="user-voucher-card__child"
          is-child
          :key="child.id"
          :voucher="child"
          @qr="onVoucherShow"
          v-for="child in children"
        />
      </div>
    </div>

    <modal-voucher
      :visible="showVoucher"
      :voucher="selected"
      @close="onVoucherClose"
      v-if="showVoucher"
    />

    <div 
      class="user-voucher-card__footer" 
      v-if="!isTemplate">
      <!-- <div class="user-voucher-card__code">{{ voucher.code }}</div> -->

      <div class="user-voucher-card__factions">
        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          class="user-voucher-card__faction"
          icon-post="layout"
          :size="$pepper.Size.S"
          @click.stop.prevent="onQr"
        >{{ $t('user-portal.voucher_see_qr_code') }}</actions-button>

        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          class="user-voucher-card__faction"
          icon-post="copy"
          :size="$pepper.Size.S"
          @click.stop.prevent="onCopy"
        >{{ voucher.code }}</actions-button>
        <!-- ></actions-button> -->
      </div>
      <!-- <button class="user-voucher-card__copy">
        <ui-icon class="user-voucher-card__copy-icon" glyph="copy" />
      </button> -->
    </div>
  </component>
</template>

<script>
import ModalVoucher from '@/modules/user/components/modals/voucher.vue'

import MixinCurrency from '@/helpers/currency'

export default {
  name: 'UserVoucherCard',

  components: {
    ModalVoucher
  },

  mixins: [
    MixinCurrency
  ],

  props: {
    children: {
      type: Array, 
      default: () => []
    },

    generation: {
      type: Object,
      default: null,
    },

    interactive: {
      type: Boolean,
      default: true
    },

    isChild: {
      type: Boolean, 
      default: false
    },

    isTemplate: {
      type: Boolean,
      default: false,
    },

    voucher: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showVoucher: false,
      selected: null
    }
  },

  computed: {
    classes() {
      return {
        'user-voucher-card': true,
        '-is-interactive': this.interactive === true,
        '--details': this.hasChildren,
      }
    },

    discount() {
      if(this.voucher.amountType === 'number') {
        return `-${ this.toCurrency(this.voucher.amount) }`
      }

      return `-${ this.voucher.amount } %`
    },

    hasChildren() {
      return this.children && this.children.length > 0
    },

    lastGeneration() {
      let ret = this.$basil.get(this.generation, 'lastGenerationDate', null)
      return ret ? this.$basil.i18n.datetime(ret) : null
    },

    name() {
      if(this.isChild) {
        return this.$basil.get(this.voucher, 'code')
      }
      return this.$basil.isObject(this.voucher.name) ? this.$basil.get(this.voucher, 'name.all', null) : this.voucher.name 
    }, 

    nextGeneration() {
      let ret = this.$basil.get(this.generation, 'nextRunAt', null)
      return ret ? this.$basil.i18n.datetime(ret) : null
    },

    statusAttrs() {
      return {
        class: {
          'user-voucher-card__status': true,
          [this.statusHue]: true
        },
        size: this.$pepper.Size.S
      }
    },

    statusHue() {
      let ret = null

      if(this.statuses.partiallyDepleted) {
        ret = '-orange' 
      }

      if(this.statuses.redeemed) {
        ret = '-green'
      }

      if(this.statuses.depleted) {
        ret = '-red' 
      }

      if(this.statuses.expired || this.statuses.inactive) {
        ret = '-gray'
      }
      
      if(this.statuses.active || ret == null) {
        ret = '-blue' 
      }

      return ret
    },

    statusLabel() {
      return !this.isTemplate ? this.$t(`user-portal.user_voucher_status_${this.$basil.get(this.voucher, 'status')}`) : null
    },

    statuses() {
      let ret = {
        active: this.$basil.get(this.voucher, 'status', 'unresolved') === 'active',
        expired: this.$basil.get(this.voucher, 'status', 'unresolved') === 'expired',
        inactive: this.$basil.get(this.voucher, 'status', 'unresolved') === 'inactive',
        redeemed: this.$basil.get(this.voucher, 'status', 'unresolved') === 'redeemed',
        partiallyDepleted: this.$basil.get(this.voucher, 'status', 'unresolved') === 'partially_depleted',
        depleted: this.$basil.get(this.voucher, 'status', 'unresolved') === 'depleted',
      }

      return ret
    },


    validityPeriodDate() {
      return this.voucher.to ? this.$basil.i18n.date(new Date(this.voucher.to)) : null
    }
  },

  methods: {
    async onCopy() {
      if(navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(this.voucher.code)

          this.$notification({
            title: this.$t('user-portal.copy_to_clipboard'),
            type: 'success'
          })
        } catch(e) {
          this.$notification({
            title: this.$t('user-portal.copy_to_clipboard_failed'),
            type: 'error'
          })
        }
      } else {
        let tempInput = document.createElement('input')
        tempInput.setAttribute('style', 'opacity: 0; position: fixed; top: 0; z-index: -1')
        tempInput.value = value || null
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        ctx.done()
        document.body.removeChild(tempInput)
      }
    },

    onQr() {
      this.$emit('qr', this.voucher)
    },

    onVoucherClose() {
      this.showVoucher = false
      this.selected = null
    },

    onVoucherShow(voucher) {
      this.selected = voucher
      this.showVoucher = true
    },
  },
}
</script>
