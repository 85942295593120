<template>
  <layout-modal 
    v-bind="attrs"
    v-on="listeners">
    <template #header>
      <div class="row">
        <h2 class="layout-modal__title">{{ title }}</h2>

        <data-tag 
          :appearance="$pepper.Appearance.PRIMARY"
          class="modal-voucher__tag"
        >{{ $t(`user-portal.user_voucher_type_${ voucher.type }`) }}</data-tag>
      </div>
    </template>

    <div 
      class="modal-voucher__qr" 
      v-if="qrSrc">
      <img 
        :alt="title"
        :src="qrSrc" 
      />
    </div>

    <article class="layout-modal__article">
      <forms-input
        icon-post-interactive
        icon-post="copy"
        @iconClick="onCopy"
        :value="voucher.code"
      />

      <p 
        class="modal-voucher__expires" 
        v-if="validityPeriodDate">
        {{ $t('user-portal.voucher_to_date', { date: validityPeriodDate }) }}
      </p>
    </article>

    <template #footer>
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        @click="back"
      >{{ $t('user-portal.action_close') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import Config from '@/config'

import LayoutModal from '@/components/layouts/modal.vue'

export default {
  name: 'ModalVoucher',

  components: {
    LayoutModal,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    voucher: {
      type: Object,
      required: true,
    },
  },

  computed: {
    attrs() {
      return {
        classes: {
          'modal-voucher': true
        },
        size: this.$pepper.Size.S,
        visible: this.visible,
      }
    },

    listeners() {
      return {
        close: this.back
      }
    },

    qrSrc() {
      if(!this.voucher.code) {
        return null
      }

      return `${Config.transports.cp.api}qr?text=${encodeURIComponent(this.voucher.code)}&format=svg&size=256`
    },

    title() {
      let ret = this.$basil.get(this.voucher, 'name')
      return this.$basil.isObject(ret) && ret.all ? ret.all : ret
    },

    validityPeriodDate() {
      return this.voucher.to ? this.$basil.i18n.date(new Date(this.voucher.to)) : null
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },

    async onCopy() {
      if(navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(this.voucher.code)

          this.$notification({
            title: this.$t('user-portal.copy_to_clipboard'),
            type: 'success'
          })
        } catch(e) {
          this.$notification({
            title: this.$t('user-portal.copy_to_clipboard_failed'),
            type: 'error'
          })
        }
      } else {
        let tempInput = document.createElement('input')
        tempInput.setAttribute('style', 'opacity: 0; position: fixed; top: 0; z-index: -1')
        tempInput.value = this.voucher.code || null
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        ctx.done()
        this.$notification({
          title: this.$t('user-portal.copy_to_clipboard'),
          type: 'success'
        })
        document.body.removeChild(tempInput)
      }
    },
  }
}
</script>